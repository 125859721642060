import { Injectable} from '@angular/core';
import RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
  ext:string;
  name:string;
}

@Injectable()
export class AudioRecordingService {


  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();


  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }


  startRecording() {

    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    // let conf: any = {
    //   audio:{
    //     mandatory: {
    //       echoCancellation: false,
    //       googAutoGainControl: false,
    //       googNoiseSuppression: false,
    //       googHighpassFilter: false,
    //     }
    //   }
    // }
      let conf: any ={ audio: true }
      // let conf:any ={ audio:{
      //   autoGainControl: false,
      //   channelCount: 2,
      //   echoCancellation: false,
      //   latency: 0,
      //   noiseSuppression: false,
      //   sampleRate: 48000,
      //   sampleSize: 16,
      //   volume: 1.0
      //     }}
    navigator.mediaDevices.getUserMedia(conf)
      .then(s => {
        this.stream = s;
        this.record();
      }).catch(error => {
        this._recordingFailed.next();
      });

  }

  abortRecording() {
    this.stopMedia();
  }


  pauseRecording() {
      this.recorder.pause();
  }
  resumeRecording(){
    this.recorder.resume();
  }

  private record() {
//       sampleRate: 44100,
// bufferSize: 4096,
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/mp3'
    });

    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
      },
      500
    );
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop((blob) => {
        if (this.startTime) {
          const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
          this.stopMedia();
          this._recorded.next({ blob: blob, title: mp3Name, ext: '.mp3', name:'audio_' +new Date().getTime()});
        }
      }, () => {
        this.stopMedia();
        this._recordingFailed.next();
      });
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

}
