export class AppSettings {

  //Production
    static readonly  SHOPIFYLINK = "https://makemomentos.com/collections/all";
    static readonly STATIC_REST_ENDPOINT ="https://app.makemomentos.com/mapi"
    static readonly IMAGEPATH = "https://app.makemomentos.com/mImage"
    static readonly QRPATH = "https://momentosprod.s3.us-east-2.amazonaws.com/QRCode/"
 //Production ends

  // Staging
    // static readonly STATIC_REST_ENDPOINT = "https://www.momentos.vconnexservices.com/mapi"
    // static readonly IMAGEPATH = "https://www.momentos.vconnexservices.com/mImage"
    // static readonly SHOPIFYLINK = "https://vconnexbooks.myshopify.com/";
  //stagging ends

  // Static REST API Base Url
  // static readonly STATIC_REST_ENDPOINT = "http://localhost:3001/mapi"

  static readonly port: string = window.location.port===''?window.location.port:':'+window.location.port;
  static readonly DYNAMIC_REST_ENDPOINT: string = window.location.protocol+'//'+window.location.hostname+ AppSettings.port + '/ayapi';
  // Dynamic REST API Base Url fetch from browser bar
  // Assign static or dynamic API
  static readonly REST_ENDPOINT = AppSettings.STATIC_REST_ENDPOINT;
  static readonly PAGE_SIZE = 15;
  static readonly PAGE_SIZE_OPTIONS = [2,15,30,45,60];
  static readonly SECRET_KEY = 'MOMENTOSPROJECTKEY';
  static readonly AUTH_SECRET_KEY = "MOMENTOS_SECRET";
  static readonly  API_PASSWORD_SECRET_KEY = "MOMENTOS_RESET_PASSWORD";
  static readonly  URLENCRYPT = "MOMENTOS_URL";

}
