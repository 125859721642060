
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

@Injectable()
export class AlertService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }
  showSuccess(message:string, title:string){
    const success = swal.fire({
      title: "",
      text:message,
      timer: 10000,
      icon: "success",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
    return success;
  };

  showError(message:string, title:string){
    const error = swal.fire({
      title: "",
      text: message,
      timer: 5000,
      icon: "error",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
    return error;
  }

  showErrorWithTimer(message:string, title:string,timer:number){
    const error = swal.fire({
      title: "",
      text: message,
      timer: timer,
      icon: "error",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
    return error;
  }

  redirectSuccess(message:string, url:any){
    const success = swal.fire({
      title: "",
      text:message,
      timer: 10000,
      icon: "success",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    }).then(okay => {
      if (okay) {
        this.router.navigate(url, {relativeTo: this.route});
       }
    });
    return success;
  };

  redirectError(message:string, url:any){
    const error = swal.fire({
      title: "",
      text: message,
      timer: 5000,
      icon: "error",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    }).then(okay => {
      if (okay) {
        this.router.navigate(url, {relativeTo: this.route});
       }
    });
    return error;
  };
  showInfo(message:string, title:string){
    const success = swal.fire({
      title: "",
      text:message,
      timer: 10000,
      icon: "warning",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
    return success;
  };
}
