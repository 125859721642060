import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppService } from '../services/app.service';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,private appService:AppService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser =  this.appService.getLocalStorageItem('mudata',true);

    if (currentUser) {
      return true;
    }
    else {
      this.router.navigate(['/app/login']);

    }
  }
}
