import { AppSettings } from '../../app.settings';
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: AppSettings.SHOPIFYLINK, title: 'Visit Store', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
  },
  {
    path: '/pages/about', title: 'About Us', icon: 'ft-info', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/contact', title: 'Contact Us ', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/help', title: 'Help ', icon: 'ft-help-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/library/list', title: 'My Library', icon: 'ft-book-open', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page', title: 'Page', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  // },
//   {
//     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
//     submenu: [
//         { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//         {
//             path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
//             submenu: [
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//             ]
//         },
//     ]
// },
];
