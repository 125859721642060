import { Injectable } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { AppEncryptDecryptService } from './app-encrypt-decrypt';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// import * as ffmpeg from 'ffmpeg.js/ffmpeg-mp4';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private appEncryptDecryptService: AppEncryptDecryptService) {
  }
  // get the local storage item with decrypted value
  public getLocalStorageItem(key: string, parsingNeeded: boolean) {
    const itemVal = localStorage.getItem(key);
    if (itemVal == null) {
      return null;
    }
    const decryptedValue = this.appEncryptDecryptService.get(AppSettings.SECRET_KEY, itemVal);
    if (!parsingNeeded) {
      return decryptedValue;
    } else {
      return JSON.parse(decryptedValue);
    }
  }
 // set the local storage item with encrypted value
  public setLocalStorageItem(key: string, value: any, parsingNeeded: boolean) {
    if (!parsingNeeded) {
      return localStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, value));
    } else {
      return localStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, JSON.stringify(value)));
    }
  }

  public  setUrlEncryption(value) {
    const encryptedUrl = this.appEncryptDecryptService.setUrl(AppSettings.URLENCRYPT, value);
    return encryptedUrl;

  }

  public  getUrlEncryption(value) {
    const decryptedUrl = this.appEncryptDecryptService.getUrl(AppSettings.URLENCRYPT, value);
    return decryptedUrl;

  }
   // get the session storage item with decrypted value
  public getSessionStorageItem(key: string, parsingNeeded: boolean) {
    const itemVal = sessionStorage.getItem(key);
    if (itemVal == null) {
      return null;
    }
    const decryptedValue = this.appEncryptDecryptService.get(AppSettings.SECRET_KEY, itemVal);
    if (!parsingNeeded) {
      return decryptedValue;
    } else {
      return JSON.parse(decryptedValue);
    }
  }
  // set the local storage item with encrypted value
  public setSessionStorageItem(key: string, value: any, parsingNeeded: boolean) {
    if (!parsingNeeded) {
      return sessionStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, value));
    } else {
      return sessionStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, JSON.stringify(value)));
    }
  }

  public isLaptop(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return !(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent));
  }

  public isTablet(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
  }


  // get the single array from two arrays
  mergeUniqueArray(arr, comp) {

    let unique = arr
      .map(item => item[comp])

       // store the keys of the unique objects
      .map((item, i, final) => final.indexOf(item) === i && i)

      // eliminate the duplicate keys & store unique objects
      .filter(item => arr[item]).map(item => arr[item]);

     return unique;
  }

  // get the format for email in href
  mailto(emailAddress: string) {
    return "mailto:" + emailAddress
  }

  onImgError(event) {
    event.target.src = 'https://momentos.s3.us-east-2.amazonaws.com/ProductImage/default.png';
  }

     // get the date from Unix time
     unixDate(unixtime) {
      if (unixtime) {
        const u = new Date(unixtime);
        const month = u.getMonth() + 1;
        const dates = month + '/' + u.getDate() + '/' + u.getFullYear();
        return dates;
      } else {
        const dates = '';
        return dates;
      }
    };

     // get the date time from unix time
    public unixTime(unixtime) {
      if (unixtime) {
        const u = new Date(unixtime);
        const amOrPm = (u.getHours() < 12) ? 'AM' : 'PM';
        const hour = (u.getHours() < 12) ? u.getHours() : u.getHours() - 12;
        const month = u.getMonth() + 1;
        const minutes: number = u.getMinutes();
        let min: string;
        if (minutes < 10) {
          min = '0' + minutes.toString();
        } else {
          min = minutes.toString();
        }
        // const dates = month + '/' + u.getDate() + '/' + u.getFullYear() + ' ' + hour + ':' + min + ' ' + amOrPm;
        const dates =  hour + ':' + min + ' ' + amOrPm;
        return dates;
      } else {
        const dates = '';
        return dates;
      }
    }

    paginate(
      totalItems: number,
      currentPage: number = 1,
      pageSize: number = 10,
      maxPages: number = 10
  ) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);

      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }

      let startPage: number, endPage: number;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
      let nextPage = currentPage +1;
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          nextPage:nextPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
  }

  getScreenOrientation(): string {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;

    return isLandscape ? "landscape" : "portrait";
  }

  // async convertToMp4(blob: Blob): Promise<Blob> {
  //   const ffmpeg = createFFmpeg({ log: true });

  //   // Load the ffmpeg-core script
  //   await ffmpeg.load();

  //   // Write the input file to the virtual file system of ffmpeg.js
  //   ffmpeg.FS('writeFile', 'input', await fetchFile(blob));

  //   // Run the ffmpeg command to convert the input file to MP4
  //   await ffmpeg.run('-i', 'input', 'output.mp4');

  //   // Read the converted file from the virtual file system
  //   const data = ffmpeg.FS('readFile', 'output.mp4');

  //   // Cleanup ffmpeg resources
  //   ffmpeg.FS('unlink', 'input');
  //   ffmpeg.FS('unlink', 'output.mp4');

  //   return new Blob([data.buffer], { type: 'video/mp4' });
  // }

  // convertToMp4(blob: Blob): Promise<Blob> {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.onload = () => {
  //       const arrayBuffer = fileReader.result as ArrayBuffer;
  //       const data = new Uint8Array(arrayBuffer);

  //       // Run ffmpeg.js to convert the input file to MP4
  //       const result = ffmpeg({
  //         MEMFS: [{ name: 'input', data }],
  //         arguments: ['-i', 'input', 'output.mp4'],
  //       });

  //       // Get the converted file data from the result
  //       const outputData = result.MEMFS[0].data;

  //       // Cleanup ffmpeg.js resources
  //       ffmpeg.FS('unlink', 'input');
  //       ffmpeg.FS('unlink', 'output.mp4');

  //       // Resolve the converted MP4 Blob
  //       resolve(new Blob([outputData], { type: 'video/mp4' }));
  //     };

  //     fileReader.onerror = reject;
  //     fileReader.readAsArrayBuffer(blob);
  //   });
  // }

}
