import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})

export class AppEncryptDecryptService {
  

  set(keys, value){

    return CryptoJS.AES.encrypt(value, keys).toString();
  }

  get(keys, value){
    return CryptoJS.AES.decrypt(value, keys).toString(CryptoJS.enc.Utf8);
  }

  getPassword(keys, value){
    const decryptedValue = CryptoJS.AES.decrypt(value, keys).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedValue);
  }

  setUrl(keys, value){
    var encrypt = CryptoJS.AES.encrypt(value, keys).toString();
    return encrypt;
  }

  getUrl(keys, value){

    var decrypt =  CryptoJS.AES.decrypt(value, keys).toString(CryptoJS.enc.Utf8);
    return decrypt;
  }
}
