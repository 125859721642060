import {Injectable} from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class ExceljsService {


  constructor(
    private appService: AppService) {
  }

  generateExcel(title, header, rows, colSize) {
    // Excel Title, Header, Data
    const pageTitle = 'Momentos';
    // Create workbook and worksheet
    let workbook = new Excel.Workbook;
    let worksheet = workbook.addWorksheet(title);

    // Add Page title
    worksheet.mergeCells('A1:H1');
    worksheet.getCell('H1').value = pageTitle;
    let titleRow = worksheet.getCell('H1');
    titleRow.font = {name: 'Times New Roman', family: 4, size: 14, bold: true};
    titleRow.alignment = {vertical: 'middle', horizontal: 'center'};
    // titleRow.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: {argb: 'FFA9A9A9'},
    //   bgColor: {argb: 'FFA9A9A9'}

    // };
    // Add the table title
    // worksheet.mergeCells('A2:H2');
    // worksheet.getCell('H2').value = 'Table Name: ' + title;
    // let subTitleRow = worksheet.getCell('H2');
    // subTitleRow.font = {name: 'Times New Roman', family: 4, size: 12, bold: true};
    // subTitleRow.alignment = {vertical: 'middle', horizontal: 'center'};


    // Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFDCDCDC'},
        bgColor: {argb: 'FFDCDCDC'}
      };
      cell.font = {name: 'Times New Roman', size: 10, bold: true};
      cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    });

    // Add Data and Conditional Formatting
    rows.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {name: 'Times New Roman', size: 10};
        const borderStyles = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
        row.eachCell({includeEmpty: true}, function (cell, colNumber) {
          cell.border = borderStyles;
        });
      }
    );

    // Alter the columns width
    colSize.forEach(col => {
      worksheet.getColumn(col.id).width = col.width;
      // set the alignment and wrap content
      worksheet.getColumn(col.id).alignment = {wrapText: true};
    });
    worksheet.addRow([]);


    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      const downloadDate = this.appService.unixTime(new Date());
      const pageHeading = 'MOMENTOS_' + title + '_' + downloadDate;
      let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const docTitle = pageHeading + '.xlsx';
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // To IE or Edge browser, using msSaveorOpenBlob method to download file.
        window.navigator.msSaveOrOpenBlob(blob, docTitle);
      } else {
        saveAs.saveAs(blob, docTitle);
      }

    });

  }

  generateExcelFormat(title, header, rows, colSize) {

    // Create workbook and worksheet
    let workbook = new Excel.Workbook;
    let worksheet = workbook.addWorksheet(title);
    let worksheet2 = workbook.addWorksheet('LOOKUPS');
    // Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFDCDCDC'},
        bgColor: {argb: 'FFDCDCDC'}
      };
      cell.font = {name: 'Times New Roman', size: 10, bold: true};
      cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    });
    for (let i = 0; i < colSize.length; i++) {
      if (colSize[i].dropdown === 1) {
        const cellName = colSize[i].column;
        let cellNo = 0;
        let columnDetails = colSize[i].details;
        for (let j = 0; j < columnDetails.length; j++) {
          cellNo++;
          let cellColumn = cellName + '' + cellNo;
          worksheet2.getCell(cellColumn).value = columnDetails[j];
        }
      }
    }
    // Add Data and Conditional Formatting
    rows.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {name: 'Times New Roman', size: 10};
        const borderStyles = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
        row.eachCell({includeEmpty: true}, function (cell, colNumber) {
          cell.border = borderStyles;
        });
      }
    );

    // Alter the columns width
    colSize.forEach(col => {
      worksheet.getColumn(col.id).width = col.width;
      // set the alignment and wrap content
      worksheet.getColumn(col.id).alignment = {wrapText: true};
      if (col.dropdown === 1) {
        let cellNo = 0;
        let columnLength = col.details.length + 1;
        const colFormulae = '=LOOKUPS!$' + col.column + '$1:$' + col.column + '$' + columnLength;
        for (let i = 0; i <= 100; i++) {
          cellNo++;
          let cellName = col.column + '' + cellNo;
          worksheet.getCell(cellName).dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [colFormulae],
            showErrorMessage: true,
            errorStyle: 'error',
            errorTitle: 'Error',
            error: 'Value must be in the list'
          };
        }
      }
    });
    worksheet.addRow([]);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const docTitle = title + '.xlsx';
      saveAs.saveAs(blob, docTitle);
    });

  }

  readExcel(fileName) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = fileName;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {type: 'binary'});
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      return dataString;
    };
    reader.readAsBinaryString(file);
  }


}
