import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {AppService} from './app.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    let currentToken =  this.appService.getLocalStorageItem('mtdata', false);
    if (currentToken !== "" && currentToken !== null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`,
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
    }

    return next.handle(request);
  }


}
