<div class="modal-header">
  <p class="modal-title font-medium-1 text-bold-600 mt-1">{{title}}</p>
  <!-- <h4 class="modal-title">{{title}}</h4> -->
  <div class="">
    <button type="button" class="close" aria-label="Close" (click)="close(false,{})">
      <span aria-hidden="true" class="font-large-1">&times;</span>
  </button>
  </div>

</div>
<div class="modal-body" *ngIf="!popupselector">
  <p class="font-medium-1 text-bold-500 line-height-1 mb-2">{{description}}</p>
  <p class="text-left text-dark font-small-3 mb-2 text-bold-400">{{secondary}}</p>
  <p class="text-left text-red font-small-3 mb-2 text-bold-400">{{secondary2}}</p>
  <p class="text-left text-red font-small-3 mb-2 text-bold-400">{{secondary3}}</p>
</div>
<div class="modal-body" *ngIf="popupselector">
  <p class="font-medium-1 text-bold-500 line-height-1 mb-2">{{description}}</p>
  <p class="text-left text-dark font-small-3 mb-2 text-bold-400">Total pages: {{this.Totalpages}}</p>
  <p class="text-left text-dark font-small-3 mb-2 text-bold-400">Recorded: {{this.RecordedPages}}</p>
  <p class="text-left text-dark font-small-3 mb-2 text-bold-400">Skipped: {{this.SkippedPages}}</p>
  <p class="text-left text-dark font-small-3 mb-2 text-bold-400">Not Recorded: {{this.NotRecordedPages}}</p>
</div>
<div class="modal-footer">
  <div class="row w-100 ">
      <div class="col-md-12 p-0 d-flex justify-content-end" >
        <!-- [class]="cancelClass" [class]="actionClass"-->
          <button type="button" class="btn btn-danger" *ngIf="showClose" (click)="close(false,{})">{{cancelButton}}</button>&nbsp;
          <button type="button"  class="btn btn-primary" (click)="onSubmit()">{{actionButton}}</button>
      </div>

  </div>
</div>
