import { Routes} from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'library',
    loadChildren: () => import('../../library/library.module').then(m => m.LibraryModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../account/account.module').then(m => m.AccountModule)
  }
];
