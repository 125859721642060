import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../app.settings';

@Injectable()
export class AuthService {

  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(private http: HttpClient,public router: Router) {
   

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(user: any) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)
    return this.http.post(AppSettings.REST_ENDPOINT + '/login', user);

    
  }

  signinAdmin(user: any) {
    return this.http.post(AppSettings.REST_ENDPOINT + '/loginAdmin', user);
  }

  authorizeToken(user: any) {
    return this.http.post(AppSettings.REST_ENDPOINT + '/authorizeToken', user);
  }
  logout() {
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }
}
