<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
        <div class="navbar-brand-left">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item d-flex">
                  <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()" *ngIf="isSmallScreen">
        <i class="ft-menu font-medium-3"></i>
      </div>
              <div class="logo"><a class="logo-text" (click)="onLogoRedirection()" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl" style="width: 220px !important;"></div>
                  <!-- <span
                    class="text">Make Momentos</span> -->
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">

          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <button class="btn vist-store" type="button" (click)="goToLink()">Visit Store</button>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link text-dark navs-tabs" [ngClass]="selectedTab === 'about'? 'active-tab':''" (click)="redirectToPage('/pages/about', 'about');" >About Us</a>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link text-dark navs-tabs" [ngClass]="selectedTab === 'contact'? 'active-tab':''" (click)="redirectToPage('/pages/contact', 'contact');">Contact Us</a>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link text-dark navs-tabs" [ngClass]="selectedTab === 'help'? 'active-tab':''" (click)="redirectToPage('/pages/help' ,'help');">Help</a>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link text-dark navs-tabs" [ngClass]="selectedTab === 'library'? 'active-tab':''" (click)="redirectToPage('/library/list','library');">My Library</a>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end text-dark" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>

              <i class="ft-user font-medium-3" [ngClass]="selectedTab === 'user'? 'active-tab':''" ></i>


            </a>
            <!-- *ngIf="showMenu" -->
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
<!-- routerLink="/account" -->
              <a class="dropdown-item"  (click)="redirectToPage('/account', 'user')" *ngIf="loginUser">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2 navs-tabs"></i><span>My Account</span>
                </div>
              </a>
              <a class="dropdown-item" *ngIf="showSettings=== true && loginUser" (click)="redirectToPage('/setting', 'user')">
                <div class="d-flex align-items-center">
                  <i class="ft-settings mr-2 navs-tabs"></i><span>Settings</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <!-- routerLink="/pages/home" -->
              <a class="dropdown-item" (click)="logout()" *ngIf="loginUser">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2 navs-tabs"></i><span>Logout</span>
                </div>
              </a>
              <a class="dropdown-item" (click)="redirectTo('/app/login')" *ngIf="!loginUser" >
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2 navs-tabs"></i><span>Login</span>
                </div>
              </a>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>





</nav>
