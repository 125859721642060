import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class HttpUtilsService {

  loadingSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  
  getFindHTTPParams(queryParams): HttpParams {
    const params = new HttpParams()
      .set('lastNamefilter', queryParams.filter)
      .set('sortOrder', queryParams.sortOrder)
      .set('sortField', queryParams.sortField)
      .set('pageNumber', queryParams.pageNumber.toString())
      .set('pageSize', queryParams.pageSize.toString());

    return params;
  }

  getHTTPHeaders(): HttpHeaders {
    const result = new HttpHeaders();
    result.set('Content-Type', 'application/json');
    return result;
  }

  getHttpErrorMessages(error: any): any {
    let errorMessages = [];
    if (typeof error.field !== 'undefined' && error.field.length > 0) {
      _.forEach(error.field, function(fieldError) {
        if (fieldError.code === 'NotEmpty') {
          errorMessages.push(fieldError.field + ' ' + fieldError.defaultMessage);
        } else {
          errorMessages.push(fieldError.defaultMessage);
        }
      });
      return errorMessages.toString();
    } else {
      if (typeof error.status !== 'undefined' && typeof error.message !== 'undefined') {
        return error.message;
      }
      return error;
    }
  }

}
