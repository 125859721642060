import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router , ActivatedRoute } from '@angular/router';
import { AppService } from '../services/app.service';
import {AppSettings} from '../../app.settings'
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/makeLogo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  selectedTab: string="";
  url:string;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();
  showSettings:boolean = false;
  loginUser:any ={}
  public config: any = {};
  showMenu: boolean = false;
  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
    private appService:AppService,
    private configService: ConfigService, private cdr: ChangeDetectorRef) { // array of states
      console.log(this.router.url);
      this.url = this.router.url;
      switch(this.url){
        case '/pages/about':
          this.selectedTab = 'about';
          break;
        case '/pages/contact':
          this.selectedTab = 'contact';
          break;
        case '/pages/help':
          this.selectedTab = 'help';
          break;
        case '/library/list':
          this.selectedTab = 'library';
          break;
        case '/setting':
          this.selectedTab = 'user';
          break;
        case '/account':
          this.selectedTab = 'user';
          break;
        // default:
        //   this.selectedTab = 'library';
        //   break;
      }
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });
      if(this.loginUser){
        this.showMenu = true;
      }
  }

  ngOnInit() {
    this.listItems = LISTITEMS;
    this.url = this.router.url;
    switch(this.url){
      case '/pages/about':
        this.selectedTab = 'about';
        break;
      case '/pages/contact':
        this.selectedTab = 'contact';
        break;
      case '/pages/help':
        this.selectedTab = 'help';
        break;
      case '/library/list':
        this.selectedTab = 'library';
        break;
      case '/setting':
        this.selectedTab = 'user';
        break;
      case '/account':
        this.selectedTab = 'user';
        break;
      // default:
      //   this.selectedTab = 'library';
      //   break;
    }
    if (this.innerWidth < 1024) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
    this.loginUser = this.appService.getLocalStorageItem('mudata', true);
    if(this.loginUser){
      this.showMenu = true;
      this.showSettings = this.loginUser.UserRole === 'Admin'? true:false;
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1024) {

      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  onLogoRedirection(){
    this.selectedTab = 'library';
    const userData = this.appService.getLocalStorageItem('mudata', true);
    if(userData){
      this.router.navigate(['/library/list'], {relativeTo: this.route});
    }else{
      this.router.navigate(['/app/login'], {relativeTo: this.route});
    }
  }
  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    }
    else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    }
    else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }

  redirectToPage(url, tab){
    this.selectedTab = tab;
    this.router.navigate([url], {relativeTo: this.route});
  }

  logout(){
    const url='/app/login'
    localStorage.removeItem('mudata');
    localStorage.removeItem('mtdata');
    localStorage.removeItem('from');
    localStorage.removeItem('narrate');
    this.router.navigate([url], {relativeTo: this.route});
  }
  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
  goToLink() {
    window.open(AppSettings.SHOPIFYLINK, "_blank");
  }
}
