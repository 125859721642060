import { Component, OnInit,EventEmitter, Output, Input} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() selectedRow: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  showClose = true;
  title = '';
  description = '';
  waitDescription = '';
  secondary = '';  secondary2 = '';  secondary3 = '';
  actionButton= 'OK';
  cancelButton = 'Cancel';
  actionClass: any = '';
  cancelClass: any = '';
  popupselector: boolean = false;
  Totalpages: any;
  RecordedPages: any;
  SkippedPages: any;
  NotRecordedPages: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.actionClass = this.selectedRow.ACTION_BUTTON_CLASS;
    this.cancelClass = this.selectedRow.CANCEL_BUTTON_CLASS;
    this.title = this.selectedRow.TITLE;
    this.description = this.selectedRow.DESCRIPTION;
    this.secondary = this.selectedRow.SECONDARY;
    this.secondary2 = this.selectedRow.SECONDARY2;
    this.secondary3 = this.selectedRow.SECONDARY3;
    this.waitDescription = this.selectedRow.WAIT_DESCRIPTION;
    this.actionButton = this.selectedRow.ACTION_BUTTON;
    this.cancelButton = this.selectedRow.CANCEL_BUTTON;
    this.showClose = this.selectedRow.SHOW_CLOSE;
    this.popupselector = this.selectedRow.popupselector;
    this.Totalpages = this.selectedRow.Totalpages;
    this.RecordedPages = this.selectedRow.RecordedPages;
    this.SkippedPages = this.selectedRow.SkippedPages;
    this.NotRecordedPages = this.selectedRow.NotRecordedPages;

  }

  close(res,data){
    this.passEntry.emit({result:res , data:data});
    this.activeModal.close('Modal Closed');
  }

  onSubmit(){
    this.close(true,{});
  }
}
